import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import title_image from '../../../images/titles/business_process_improvement.jpg'
import lean_process from '../../../images/pages/lean_process.png'
import person from '../../../images/person.png'

const pageTitle = "Business Process Improvement (Lean/Six Sigma)";
const pageSLug = "business-process-improvement";
class ProcessCapability extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Building quality processes that grow with a business.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>How BPI can work with your organization.</h3>
                   <p>BPI leads to quality improvement, service enhancement, cost reductions, 
                       and productivity increases in business processes and activity. Many companies 
                       take a BPI program to maximize their operational performance and gain a competitive 
                       advantage in the marketplace.</p>
                    <h4>BPI Overview</h4>
                    <p>With Business Process Improvement you can deliver greater value and create a 
                        sustainable framework for improvement.</p>
                        <span className="break"></span>
                    <h4>Lean Overview</h4>
                    <p>Lean is fundamentally about creating more value while respecting the people in 
                        your organization. At its core, it is a philosophy for managing a business. 
                        It is more than just a collection of tools, techniques, and projects. Lean is 
                         philosophy that you're constantly striving for, not just something else you do.</p>
                    <img src={lean_process} />
                    <span className="break"></span>
                    <h4>Six Sigma Overview</h4>
                    <p>Six Sigma primarily focuses on improving the quality of products and services. 
                        This methodology focuses on identifying and removing defects and minimizing variability 
                        in processes creating sustainable and guaranteed improvement. In Six Sigma, all procedures 
                        are defined, measured, analyzed, improved, and controlled. It has the following value targets:</p>
                        <ul>
                            <li>Increase in profits</li>
                            <li>Reduce Pollutions</li>
                            <li>Increase Customer Satisfaction</li>
                            <li>Reduce Process Cycle Time</li>
                        </ul>
                        <span className="break"></span>
                    <h4>Business Process Improvement Disciplines</h4>
                    <p>Each discipline of BPI is stand-alone; however, they also work well together. Nuerex's experts 
                        can analyze and determine which of these solutions are the best fit for your organization.</p>
                    <span className="break"></span>
                    <h4>Lean</h4>
                    <p><b>DEFINITION: A customer-centric approach used to improve processes through the elimination of waste.</b></p>
                    <p><b>PHILOSOPHY: </b>Maximize customer value while removing non-value-adding activities.</p>
                    
                    <span className="break"></span>
                    <h4>Six Sigma</h4>
                    <p><b>DEFINITION: Drive improvements by managing the variations and reducing defects in a process.</b></p>
                    <p><b>PHILOSOPHY: </b>All processes can be defined, analyzed, measured, improved, and controlled to deliver maximum performance.</p>

                    <span className="break"></span>
                    <h4>Rapid Improvement</h4>
                    <p><b>DEFINITION: A streamlined, fast improvement framework that is action-oriented and addresses known issues.</b></p>
                    <p><b>PHILOSOPHY: </b>Easy, quick fixes that are easy to implement.</p>
                    <span className="break"></span>
                    <h4>Rapid Improvement Overview</h4>
                    <p>Rapid improvement is a technique that is used to implement solutions when the problem is clearly defined, 
                        and solutions are known. 
                        Not all challenges require complex solutions. Rapid is a tactical approach that can quickly deliver value.</p>
                    <span className="break"></span>
                        <div className="contact-box">
                    <h3>Want to align your processes with your business strategy? Our experts can guide you there.</h3>
                        <span className="break"></span>
                        <div className="button-area">
                            <Link to="/contact-us" className="button default">Contact us</Link>
                        </div>
                    </div>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default ProcessCapability
